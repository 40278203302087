import { Row, Button } from "antd";
import React, { Fragment, useState } from "react";
import { catSubType } from "../../../config";
import { SizeTable } from "./SizeTable";
import { Colors } from "./Colors";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_COLOR_OF_PRODUCT } from "../../../graphql/modules";
import { successNotify, warnNotify } from "../../../util";
import { ColorsGallery } from "../Gallery/ColorsGallery";

export const Inventory = ({ refetch, sizeType, colorData = {} }) => {
  // state declare
  const [inventoryState, setInventoryState] = useState({
    topSizing:
      [
        ...(colorData?.topSizing?.filter((st) => st.quantity > 0) || {}),
        ...(colorData?.topSizing?.filter((st) => st.quantity < 1) || {}),
      ] || [],
    bottomSizing:
      [
        ...(colorData?.bottomSizing?.filter((st) => st.quantity > 0) || {}),
        ...(colorData?.bottomSizing?.filter((st) => st.quantity < 1) || {}),
      ] || [],
  });

  const [colorState, setColorState] = useState({
    topColor: colorData?.topColor,
    topColorName: colorData?.topColorName,
    bottomColor: colorData?.bottomColor,
    bottomColorName: colorData?.bottomColorName,
  });

  const [galleryState, setGalleryState] = useState({
    gallery: colorData?.gallery || [],
    topGallery: colorData?.topGallery || [],
    bottomGallery: colorData?.bottomGallery || [],
  });

  // handle submit
  const [UpdateColorMutation, { loading }] = useMutation(
    UPDATE_COLOR_OF_PRODUCT
  );
  const handleSubmit = async () => {
    const data = {
      topSizing: inventoryState.topSizing.map(
        ({ size, quantity, description }) => ({
          size: size?._id,
          quantity,
          description: description || "",
        })
      ),
      bottomSizing: inventoryState.bottomSizing.map(
        ({ size, quantity, description }) => ({
          size: size?._id,
          quantity,
          description: description || "",
        })
      ),
    };
    const colorsDataFormat = {
      topColor: colorState?.topColor?.toLowerCase(),
      bottomColor: colorState?.bottomColor?.toLowerCase(),
      topColorName:
        colorState?.topColorName?.charAt(0)?.toUpperCase() +
        colorState?.topColorName?.slice(1)?.toLowerCase(),
      bottomColorName:
        colorState?.bottomColorName?.charAt(0)?.toUpperCase() +
        colorState?.bottomColorName?.slice(1)?.toLowerCase(),
    };
    try {
      const {
        data: { UpdateProductColor },
      } = await UpdateColorMutation({
        variables: {
          productId: colorData?.productId,
          colorId: colorData?._id,
          data: {
            colorDetails: {
              ...colorsDataFormat,
              ...data,
              ...galleryState,
            },
            productId: colorData?.productId,
          },
        },
      });
      if (UpdateProductColor.success) {
        successNotify(UpdateProductColor.message);
        refetch();
      } else {
        warnNotify(UpdateProductColor.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const disbaleFullsetColor =
    !colorState.topColor ||
    !colorState.topColorName ||
    !colorState.bottomColor ||
    !colorState.bottomColorName;
  const disbaleColor = {
    topDisable: !colorState.topColor || !colorState.topColorName,
    bottomDisbale: !colorState.bottomColor || !colorState.bottomColorName,
  };

  const galleryDisale =
    sizeType === catSubType.fullSet
      ? galleryState?.gallery?.length === 0
      : galleryState[sizeType?.toLowerCase() + "Gallery"].length === 0;
  const disable =
    sizeType === catSubType.fullSet
      ? inventoryState.topSizing.length === 0 ||
      inventoryState.bottomSizing.length === 0 ||
      disbaleFullsetColor
      : inventoryState[sizeType?.toLowerCase() + "Sizing"].length === 0 ||
      disbaleColor[sizeType?.toLowerCase() + "Disable"];
  console.log('inventoryState ========> ', inventoryState)
  return (
    <div>
      <Colors
        colorState={colorState}
        setColorState={setColorState}
        sizeType={sizeType}
      />
      <Row gutter={[16, 16]} className="mb-4">
        {sizeType === catSubType.fullSet ? (
          <Fragment>
            <SizeTable
              dataSource={inventoryState.topSizing}
              inventoryState={inventoryState}
              setInventoryState={setInventoryState}
              sizeCategory="topSizing"
            />
            <SizeTable
              dataSource={inventoryState.bottomSizing}
              inventoryState={inventoryState}
              setInventoryState={setInventoryState}
              sizeCategory="bottomSizing"
            />
          </Fragment>
        ) : (
          <SizeTable
            dataSource={inventoryState[sizeType?.toLowerCase() + "Sizing"]}
            inventoryState={inventoryState}
            setInventoryState={setInventoryState}
            sizeCategory={sizeType?.toLowerCase() + "Sizing"}
          />
        )}
      </Row>
      <Row>
        <ColorsGallery
          product={colorData}
          sizeType={sizeType}
          galleryState={galleryState}
          setGalleryState={setGalleryState}
        />
      </Row>
      <div className="w-100 mt-3 text-right px-2">
        <Button
          className="px-4"
          type="primary"
          disabled={disable || galleryDisale}
          onClick={handleSubmit}
          loading={loading}
        >
          Update
        </Button>
      </div>
    </div>
  );
};
