import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Input, Modal, Switch, Tooltip } from "antd";
import React, { useState } from "react";
import { FETCH_FILTER_CATEGORYLIST_BY_PRODUCT } from "../../graphql/modules";
import { CheckCircleOutlined, SelectOutlined } from "@ant-design/icons";

const BuyOneGetOneOffer = ({
  buyOneGetOneOffer,
  setBuyOneGetOneOffer,
  onUpdateSettings,
}) => {
  const [visible, setVisible] = useState(false);

  const { data: allCategory, loading } = useQuery(
    FETCH_FILTER_CATEGORYLIST_BY_PRODUCT,
    { fetchPolicy: "network-only" }
  );
  // const { data: allCategory, loading } = useQuery(FETCH_CATEGORY);
  const { categories, categoryWithSubcategory } =
    allCategory?.FetchCategoryListExceptProduct || {};
  const category = [...(categories || []), ...(categoryWithSubcategory || [])];

  const getSelectedCategoryOrSubcategoryName = () => {
    if (buyOneGetOneOffer?.reference) {
      const data = category.find(
        (item) =>
          item._id === buyOneGetOneOffer.reference ||
          item?.subcategory?.find(
            (sub) => sub?._id === buyOneGetOneOffer.reference
          )
      );
      if (buyOneGetOneOffer.discountOnCategory) {
        return data?.name;
      } else {
        return data?.subcategory?.find(
          (sub) => sub?._id === buyOneGetOneOffer.reference
        )?.name;
      }
    }
  };

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between my-3">
          <p style={{ fontWeight: "400", fontSize: "17px" }}>
            {buyOneGetOneOffer?.isOn
              ? '"Buy one get one" offer is on'
              : '"Buy one get one" offer is off'}
          </p>
          <Switch
            checked={buyOneGetOneOffer?.isOn}
            onChange={(value) => {
              setBuyOneGetOneOffer({ ...buyOneGetOneOffer, isOn: value });
              onUpdateSettings("buyOneGetOne", {
                ...buyOneGetOneOffer,
                rewardThreshold: Number(
                  buyOneGetOneOffer?.rewardThreshold || 0
                ),
                isOn: value,
              });
            }}
          />
        </div>
        <div className="row mb-2">
          <div className="col-md-3">
            <p>
              <span style={{ fontWeight: "bold" }}>Category/Subcategory:</span>{" "}
              {getSelectedCategoryOrSubcategoryName() || "N/A"}
            </p>
            <div>
              <Button type="dashed" onClick={() => setVisible(true)}>
                Change Category/Subcategory
              </Button>
            </div>
          </div>
          <div className="col-md-3">
            <p>
              <span style={{ fontWeight: "bold" }}>
                Reward threshold (USD):
              </span>{" "}
            </p>
            <div>
              <Input
                placeholder="Enter reward threshold"
                value={buyOneGetOneOffer?.rewardThreshold || null}
                onChange={(e) => {
                  setBuyOneGetOneOffer({
                    ...buyOneGetOneOffer,
                    rewardThreshold: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            type="primary"
            disabled={!buyOneGetOneOffer?.rewardThreshold}
            onClick={() => {
              onUpdateSettings("buyOneGetOne", {
                ...buyOneGetOneOffer,
                rewardThreshold: Number(
                  buyOneGetOneOffer?.rewardThreshold || 0
                ),
              });
            }}
          >
            Update offer Info
          </Button>
        </div>
      </div>
      <Modal
        title="Select"
        open={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <div style={{ minHeight: loading ? "300px" : "unset" }}></div>
        {category?.map((item) => {
          if (item?._id !== "parentId")
            return (
              <div key={item?._id} className="mb-3">
                <div>
                  <span className="mr-2">&#8226;</span>
                  <span className="mr-3">{item?.name}</span>{" "}
                  {!item?.subcategory && (
                    <Tooltip title="Select the category">
                      <SelectOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setBuyOneGetOneOffer({
                            ...buyOneGetOneOffer,
                            discountOnCategory: true,
                            reference: item._id,
                          });
                          // onUpdateSettings("buyOneGetOne", {
                          //   ...buyOneGetOneOffer,
                          //   discountOnCategory: false,
                          //   rewardThreshold: Number(
                          //     buyOneGetOneOffer?.rewardThreshold || 0
                          //   ),
                          //   reference: item._id,
                          // });
                          setVisible(false);
                        }}
                      />
                    </Tooltip>
                  )}
                  {item?._id === buyOneGetOneOffer?.reference && (
                    <CheckCircleOutlined
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        marginLeft: "25px",
                        fontSize: "16px",
                      }}
                    />
                  )}
                </div>
                <div className="ml-5">
                  {item?.subcategory?.map((sub) => {
                    if (sub?._id !== "parentId") {
                      return (
                        <div key={sub?._id}>
                          <span className="mr-3">{sub?.name}</span>{" "}
                          <Tooltip title="Select the subcategory">
                            <SelectOutlined
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setBuyOneGetOneOffer({
                                  ...buyOneGetOneOffer,
                                  discountOnCategory: false,
                                  reference: sub._id,
                                });
                                onUpdateSettings("buyOneGetOne", {
                                  ...buyOneGetOneOffer,
                                  discountOnCategory: false,
                                  reference: sub._id,
                                });
                                setVisible(false);
                              }}
                            />
                          </Tooltip>
                          {sub?._id === buyOneGetOneOffer?.reference && (
                            <CheckCircleOutlined
                              style={{
                                color: "green",
                                fontWeight: "bold",
                                marginLeft: "25px",
                                fontSize: "16px",
                              }}
                            />
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            );
        })}
      </Modal>
    </>
  );
};

export default BuyOneGetOneOffer;
