import { Row, Button } from "antd";
import React, { Fragment, useState } from "react";
import { catSubType } from "../../../config";
import { SizeTable } from "./SizeTable";
import { Colors } from "./Colors";
import { ColorsGallery } from "../Gallery/ColorsGallery";

export const Inventory = ({ sizeType, colorData, handleUpdate, idx }) => {
  // state declare
  const [inventoryState, setInventoryState] = useState({
    topSizing:
      [
        ...(colorData?.topSizing?.filter((st) => st.quantity > 0) || {}),
        ...(colorData?.topSizing?.filter((st) => st.quantity < 1) || {}),
      ] || [],
    bottomSizing:
      [
        ...(colorData?.bottomSizing?.filter((st) => st.quantity > 0) || {}),
        ...(colorData?.bottomSizing?.filter((st) => st.quantity < 1) || {}),
      ] || [],
  });

  const [colorState, setColorState] = useState({
    topColor: colorData?.topColor,
    topColorName: colorData?.topColorName,
    bottomColor: colorData?.bottomColor,
    bottomColorName: colorData?.bottomColorName,
  });

  const [galleryState, setGalleryState] = useState({
    gallery: colorData?.gallery || [],
    topGallery: colorData?.topGallery || [],
    bottomGallery: colorData?.bottomGallery || [],
  });

  const onSubmit = () => {
    const colorsDataFormat = {
      topColor: colorState?.topColor?.toLowerCase(),
      bottomColor: colorState?.bottomColor?.toLowerCase(),
      topColorName:
        colorState?.topColorName?.charAt(0)?.toUpperCase() +
        colorState?.topColorName?.slice(1)?.toLowerCase(),
      bottomColorName: colorState?.bottomColorName?.charAt(0)?.toUpperCase() +
        colorState?.bottomColorName?.slice(1)?.toLowerCase()
    };
    const data = {
      ...galleryState,
      ...colorsDataFormat,
      ...inventoryState
    }
    handleUpdate(data, idx)
  }
  return (
    <div>
      <Colors
        colorState={colorState}
        setColorState={setColorState}
        sizeType={sizeType}
      />
      <Row gutter={[16, 16]} className="mb-4">
        {sizeType === catSubType.fullSet ? (
          <Fragment>
            <SizeTable
              dataSource={inventoryState.topSizing}
              inventoryState={inventoryState}
              setInventoryState={setInventoryState}
              sizeCategory="topSizing"
            />
            <SizeTable
              dataSource={inventoryState.bottomSizing}
              inventoryState={inventoryState}
              setInventoryState={setInventoryState}
              sizeCategory="bottomSizing"
            />
          </Fragment>
        ) : (
          <SizeTable
            dataSource={inventoryState[sizeType?.toLowerCase() + "Sizing"]}
            inventoryState={inventoryState}
            setInventoryState={setInventoryState}
            sizeCategory={sizeType?.toLowerCase() + "Sizing"}
          />
        )}
      </Row>
      <Row>
        <ColorsGallery
          product={colorData}
          sizeType={sizeType}
          galleryState={galleryState}
          setGalleryState={setGalleryState}
        />
      </Row>
      <div className="w-100 mt-3 text-right px-2">
        <Button
          className="px-4"
          type="primary"
          //   disabled={disable || galleryDisale}
          onClick={onSubmit}
        //   loading={loading}
        >
          Update
        </Button>
      </div>
    </div>
  );
};
