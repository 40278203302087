import React from "react";
import { DynamicList } from "../components/Shared";
import { useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  ADD_COLOR_INVENTORY,
  FETCH_SINGLE_PRODUCT,
  UPDATE_PRODUCT,
} from "../graphql/modules";
import { Button, Collapse, Modal, Row, Spin } from "antd";
import {
  AccessoriesInventory,
  Gallery,
  ClothInventory,
  ProductInfo,
} from "../components/EditProduct";
import { successNotify, warnNotify } from "../util";
import {
  CaretRightFilled,
  DatabaseFilled,
  InfoCircleFilled,
  PictureFilled,
} from "@ant-design/icons";
import { useState } from "react";
import { Colors } from "../components/EditProduct/Inventory/Colors";
import { SizeTable } from "../components/EditProduct/Inventory/SizeTable";
import { catSubType } from "../config";
import { Fragment } from "react";
import { ColorsGallery } from "../components/EditProduct/Gallery/ColorsGallery";

export const EditProduct = () => {
  const { id } = useParams();

  const location = useLocation();
  const sizeType = new URLSearchParams(location.search).get("size-type");

  // state declare
  const [open, setOpen] = useState(false);
  const defaulState = {
    inventoryState: {
      topSizing: [],
      bottomSizing: [],
    },
    galleryState: {
      gallery: [],
      topGallery: [],
      bottomGallery: [],
    },
    colorState: {
      topColor: "",
      topColorName: "",
      bottomColor: "",
      bottomColorName: "",
    },
  };
  const [inventoryState, setInventoryState] = useState({
    ...defaulState.inventoryState,
  });
  const [galleryState, setGalleryState] = useState({
    ...defaulState.galleryState,
  });

  const [colorState, setColorState] = useState({ ...defaulState.colorState });

  // fetch product details
  const { data, loading, refetch } = useQuery(FETCH_SINGLE_PRODUCT, {
    variables: { productId: id },
    fetchPolicy: "no-cache",
  });
  const product = data?.FetchProduct?.result || {};

  // edit product

  // update product
  const [EditProductMutation, { loading: editLoading }] =
    useMutation(UPDATE_PRODUCT);
  const onEditProduct = async (data) => {
    if (!data.discountType) {
      delete data.discountType;
    }
    try {
      const {
        data: { EditProduct },
      } = await EditProductMutation({
        variables: {
          productId: id,
          data: { ...data, discountType: "all" },
        },
      });

      if (EditProduct.success) {
        successNotify(EditProduct.message);
        refetch();
      } else {
        warnNotify(EditProduct.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // add color
  const [AddColorMutation, { loading: addColorLoading }] =
    useMutation(ADD_COLOR_INVENTORY);

  const handleAddColor = async () => {
    const colorsDataFormat = {
      topColor: colorState?.topColor?.toLowerCase(),
      bottomColor: colorState?.bottomColor?.toLowerCase(),
      topColorName:
        colorState?.topColorName?.charAt(0)?.toUpperCase() +
        colorState?.topColorName?.slice(1)?.toLowerCase(),
      bottomColorName:
        colorState?.bottomColorName?.charAt(0)?.toUpperCase() +
        colorState?.bottomColorName?.slice(1)?.toLowerCase(),
    };
    const colorDetails = {
      topSizing: inventoryState.topSizing.map(({ size, quantity }) => ({
        size: size?._id,
        quantity,
      })),
      bottomSizing: inventoryState.bottomSizing.map(({ size, quantity }) => ({
        size: size?._id,
        quantity,
      })),
      ...colorsDataFormat,
      ...galleryState,
    };
    try {
      const {
        data: { AddColorOnProduct },
      } = await AddColorMutation({
        variables: {
          data: {
            productId: product?._id,
            colorDetails,
          },
        },
      });
      if (AddColorOnProduct.success) {
        successNotify(AddColorOnProduct.message);
        closeModal();
        refetch();
      } else {
        warnNotify(AddColorOnProduct.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setColorState({ ...defaulState.colorState });
    setGalleryState({ ...defaulState.galleryState });
    setInventoryState({ ...defaulState.inventoryState });
  };

  // accordion components
  const panelStyle = {
    marginBottom: 24,
    background: "#d3d3d324",
    border: "1px solid lightgray",
    borderRadius: "5px",
  };
  const items = [
    {
      key: "1",
      label: (
        <div className="d-flex align-items-cemter">
          <InfoCircleFilled
            className="mr-2 text-success"
            style={{ marginTop: "3px" }}
          />
          <h6 className="m-0">Product Info</h6>
        </div>
      ),
      children: (
        <ProductInfo
          onSubmit={onEditProduct}
          sizeType={sizeType}
          product={product}
          loading={editLoading}
        />
      ),
      style: panelStyle,
      visible: "true",
    },
    {
      key: "2",
      label: (
        <div className="d-flex align-items-cemter">
          <DatabaseFilled
            className="mr-2 text-success"
            style={{ marginTop: "3px" }}
          />
          <h6 className="m-0">
            {product?.isAccessories ? "Inventory" : "Colors & Inventory"}
          </h6>
        </div>
      ),
      children: product?.isAccessories ? (
        <AccessoriesInventory
          onSubmit={onEditProduct}
          sizeType={sizeType}
          product={product}
          loading={editLoading}
        />
      ) : (
        <ClothInventory
          product={product}
          sizeType={sizeType}
          refetch={refetch}
        />
      ),
      extra: [
        product?.isAccessories ? (
          ""
        ) : (
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
            key="1"
          >
            Add Color
          </Button>
        ),
      ],
      style: panelStyle,
      visible: "true",
    },
    {
      key: "3",
      label: (
        <div className="d-flex align-items-cemter">
          <PictureFilled
            className="mr-2 text-success"
            style={{ marginTop: "3px" }}
          />
          <h6 className="m-0">Gallery</h6>
        </div>
      ),
      children: (
        <Gallery
          onSubmit={onEditProduct}
          sizeType={sizeType}
          product={product}
          loading={editLoading}
        />
      ),
      style: panelStyle,
      visible: product?.isAccessories ? "true" : "false",
    },
  ].filter((item) => item.visible === "true");

  const disbaleFullsetColor =
    !colorState.topColor ||
    !colorState.topColorName ||
    !colorState.bottomColor ||
    !colorState.bottomColorName;
  const disbaleColor = {
    topDisable: !colorState.topColor || !colorState.topColorName,
    bottomDisbale: !colorState.bottomColor || !colorState.bottomColorName,
  };
  const disable =
    sizeType === catSubType.fullSet
      ? inventoryState.topSizing.length === 0 ||
        inventoryState.bottomSizing.length === 0 ||
        disbaleFullsetColor
      : inventoryState[sizeType?.toLowerCase() + "Sizing"].length === 0 ||
        disbaleColor[sizeType?.toLowerCase() + "Disable"];

  return (
    <DynamicList
      tableHide
      title={`update product (${
        product?.isAccessories ? "accessories" : sizeType
      } category)`}
    >
      {loading ? (
        <Spin>
          <div className="w-100" style={{ height: "500px" }}></div>
        </Spin>
      ) : (
        <Collapse
          bordered={false}
          accordion
          items={items}
          expandIcon={({ isActive }) => (
            <CaretRightFilled
              style={{ fontSize: "18px" }}
              rotate={isActive ? 90 : 0}
            />
          )}
          expandIconPosition="end"
          style={{ background: "#fff" }}
          defaultActiveKey={["1"]}
        />
      )}
      <Modal
        title="Add Color Inventory"
        open={open}
        onCancel={closeModal}
        width={1000}
        onOk={handleAddColor}
        okButtonProps={{
          disabled: disable || addColorLoading,
          loading: addColorLoading,
        }}
        okText="Add"
      >
        <Colors
          colorState={colorState}
          setColorState={setColorState}
          sizeType={sizeType}
        />
        <Row gutter={[16, 16]} className="mb-4">
          {sizeType === catSubType.fullSet ? (
            <Fragment>
              <SizeTable
                dataSource={inventoryState.topSizing}
                inventoryState={inventoryState}
                setInventoryState={setInventoryState}
                sizeCategory="topSizing"
              />
              <SizeTable
                dataSource={inventoryState.bottomSizing}
                inventoryState={inventoryState}
                setInventoryState={setInventoryState}
                sizeCategory="bottomSizing"
              />
            </Fragment>
          ) : (
            <SizeTable
              dataSource={inventoryState[sizeType?.toLowerCase() + "Sizing"]}
              inventoryState={inventoryState}
              setInventoryState={setInventoryState}
              sizeCategory={sizeType?.toLowerCase() + "Sizing"}
            />
          )}
        </Row>
        <Row>
          <ColorsGallery
            id="add-color-gallery"
            galleryState={galleryState}
            setGalleryState={setGalleryState}
            sizeType={sizeType}
          />
        </Row>
      </Modal>
    </DynamicList>
  );
};
