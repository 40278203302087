import gql from "graphql-tag";

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($productId: ID) {
    DeleteProduct(productId: $productId) {
      code
      success
      message
    }
  }
`;

export const DETACHED_PRODUCT = gql`
  mutation DetachProduct($productId: ID, $subcategoryId: ID, $categoryId: ID) {
    DetachProduct(
      productId: $productId
      subcategoryId: $subcategoryId
      categoryId: $categoryId
    ) {
      code
      success
      message
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation AddProduct($data: ProductInput) {
    AddProduct(data: $data) {
      code
      success
      message
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation EditProduct($data: ProductEditInput, $productId: ID) {
    EditProduct(data: $data, productId: $productId) {
      code
      success
      message
    }
  }
`;

export const PRODUCT_LIST_UPDATE = gql`
  mutation ($data: [ID], $categoryId: ID, $subcategoryId: ID) {
    UpdateProductPosition(
      data: $data
      categoryId: $categoryId
      subcategoryId: $subcategoryId
    ) {
      code
      success
      message
    }
  }
`;

export const CLONE_PRODUCT = gql`
  mutation AddExistingProduct(
    $productId: ID
    $subcategoryId: ID
    $categoryId: ID
    $productInput: ProductInput
  ) {
    AddExistingProduct(
      productId: $productId
      subcategoryId: $subcategoryId
      categoryId: $categoryId
      productInput: $productInput
    ) {
      code
      success
      message
    }
  }
`;

export const UPDATE_COLOR_OF_PRODUCT = gql`
  mutation UpdateProductColor(
    $productId: ID!
    $colorId: ID!
    $data: AddColorInput
  ) {
    UpdateProductColor(productId: $productId, colorId: $colorId, data: $data) {
      success
      message
    }
  }
`;

export const ADD_COLOR_INVENTORY = gql`
  mutation AddColorOnProduct($data: AddColorInput) {
    AddColorOnProduct(data: $data) {
      success
      message
    }
  }
`;

export const DELETE_COLOR_OF_PRODUCTY = gql`
  mutation DeleteProductColor($productId: ID!, $colorId: ID!) {
    DeleteProductColor(productId: $productId, colorId: $colorId) {
      success
      message
    }
  }
`;

export const FETCH_SINGLE_PRODUCT = gql`
  query FetchProduct($productId: ID) {
    FetchProduct(productId: $productId) {
      code
      success
      result {
        _id
        name
        status
        sizeType
        descriptions {
          title
          description
        }
        code
        price
        topPrice
        topDescriptions {
          title
          description
        }
        bottomDescriptions {
          title
          description
        }
        styles {
          name
          url
          quantity
        }
        bottomPrice
        discount
        haveDiscount
        discountType
        isNewProduct
        gallery
        colors {
          _id
          topColor
          bottomColor
          topColorName
          bottomColorName
          topGallery
          bottomGallery
          gallery
          topSizing {
            _id
            size {
              _id
              name
            }
            quantity
            description
          }
          bottomSizing {
            _id
            size {
              _id
              name
            }
            quantity
            description
          }
        }
        isAvailable
        quantity
        isAccessories
        category {
          _id
          name
          sizeType
        }
        subcategory {
          _id
          name
          sizeType
        }
        updatedOn
      }
    }
  }
`;
