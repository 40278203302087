import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, List, message, Select } from "antd";
import React, { useState } from "react";
import { sortOfSize } from "../../../util";
import { useQuery } from "@apollo/react-hooks";
import { FETCH_SIZING } from "../../../graphql/modules";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TextArea from "antd/es/input/TextArea";

export const SizeTable = ({
  dataSource = [],
  inventoryState,
  setInventoryState,
  sizeCategory,
}) => {
  const [editSizeIdx, setEditSizeIdx] = useState();
  const [editSizeQtc, setEditSizeQtc] = useState();
  const [editSizeDescription, setEditSizeDescription] = useState("");
  const [qtc, setQtc] = useState();
  const [sizeDescription, setSizeDescription] = useState("");
  const [size, setSize] = useState([]);

  // fetch all sizes
  const { data } = useQuery(FETCH_SIZING, {
    variables: {
      type: sizeCategory,
    },
  });
  const allSizes = data?.FetchSizing?.result || [];

  //  add size
  const addSize = (type) => {
    let newData = [...inventoryState[sizeCategory]];
    if (type === "all") {
      newData = allSizes.map((sz) => ({
        size: { name: sz?.name, _id: sz?._id },
        quantity: Number(qtc),
        description: sizeDescription,
      }));
    } else {
      for (let i = 0; size?.length > i; i++) {
        newData.push({
          description: sizeDescription,
          quantity: Number(qtc),
          size: JSON.parse(size[i]),
        });
      }
    }
    setInventoryState({
      ...inventoryState,
      [sizeCategory]: sortOfSize(
        newData,
        sizeCategory === "topSizing" ? "TOP" : "BOTTOM"
      ),
    });
    setQtc("");
    setSizeDescription("");
    setSize([]);
  };

  // edit size
  const onEditSize = () => {
    if (editSizeQtc > 0) {
      const data = [...inventoryState[sizeCategory]];
      data[editSizeIdx] = {
        ...data[editSizeIdx],
        quantity: Number(editSizeQtc),
        description: editSizeDescription || "",
      };
      setInventoryState({ ...inventoryState, [sizeCategory]: data });
      setEditSizeIdx("");
      setEditSizeQtc("");
    } else {
      message.warning("Size quantity cant be less then 1");
    }
  };

  //delete size
  const onDeleteSize = (sizeName) => {
    const data = inventoryState[sizeCategory].filter(
      ({ size }) => size.name !== sizeName
    );
    setInventoryState({ ...inventoryState, [sizeCategory]: data });
    setEditSizeIdx("");
    setEditSizeQtc("");
  };

  // drag and drop
  const onSizeDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const [removed] = inventoryState[sizeCategory].splice(source.index, 1);
    inventoryState[sizeCategory].splice(destination.index, 0, removed);
  };
  return (
    <Col lg={12}>
      <div>
        <label>{sizeCategory === "topSizing" ? "Top" : "Bottom"} Size</label>
      </div>
      <div className="d-flex align-items-baseline ">
        <div style={{ width: "100%" }}>
          <Select
            size="large"
            mode="multiple"
            placeholder="Please select"
            style={{
              width: "100%",
              marginRight: "12px",
            }}
            onChange={(value) => setSize(value)}
            value={size}
          >
            <Select.Option disabled value="">
              Size
            </Select.Option>
            {allSizes?.map((item) => (
              <Select.Option
                disabled={checkAlreadyDeclered(
                  item?.name,
                  inventoryState[sizeCategory]
                )}
                key={item._id}
                value={JSON.stringify(item)}
              >
                <div className="d-flex justify-content-between align-items-center">
                  {item.name}
                  {checkAlreadyDeclered(
                    item.name,
                    inventoryState[sizeCategory]
                  ) && <CheckOutlined style={{ color: "green" }} />}
                </div>
              </Select.Option>
            ))}
          </Select>
          <div>
            <Button
              className="mt-2 mr-3"
              type="primary"
              disabled={!qtc || size?.length > 0}
              onClick={() => addSize("all")}
            >
              All size select
            </Button>
            <Button
              className="mt-2"
              type="primary"
              disabled={inventoryState[sizeCategory].length < 1}
              onClick={() => {
                setInventoryState({ ...inventoryState, [sizeCategory]: [] });
              }}
            >
              Delete All Sizes
            </Button>
          </div>
        </div>
        <Input
          size="large"
          type="number"
          placeholder="Qty"
          value={qtc}
          onChange={(e) => setQtc(e.target.value)}
          style={{ width: "100px", margin: "0 10px" }}
        />
        <TextArea
          size="large"
          placeholder="Size description"
          value={sizeDescription}
          onChange={(e) => setSizeDescription(e.target.value)}
          style={{ width: "250px", marginRight: "10px" }}
        />
        <Button
          disabled={!qtc || size.length === 0}
          type="primary"
          size="large"
          onClick={addSize}
        >
          Add
        </Button>
      </div>
      <div style={{ width: "100%", marginTop: "30px" }}>
        <DragDropContext onDragEnd={onSizeDragEnd}>
          <Droppable droppableId="droppable3">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <List
                  dataSource={[...dataSource]}
                  bordered
                  header={
                    <div className="d-flex justify-content-between">
                      <b>Size Name</b>
                      <b>Quantity</b>
                      <b>Description</b>
                      <b>Action</b>
                    </div>
                  }
                  renderItem={({ size, quantity, description }, idx) => (
                    <Draggable
                      key={size?._id}
                      draggableId={size?._id}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <div
                          key={size?._id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <List.Item>
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <b style={{ width: "70px" }}>{size?.name}</b>
                              {idx === editSizeIdx ? (
                                <>
                                  <div>
                                    <Input
                                      style={{ width: "150px" }}
                                      value={editSizeQtc}
                                      onChange={(e) =>
                                        setEditSizeQtc(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div>
                                    <TextArea
                                      style={{ width: "150px" }}
                                      value={editSizeDescription}
                                      onChange={(e) =>
                                        setEditSizeDescription(e.target.value)
                                      }
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <b>{quantity}</b>
                                  <b>{description}</b>
                                </>
                              )}

                              <div>
                                {idx !== editSizeIdx ? (
                                  <>
                                    <EditOutlined
                                      className="mr-2"
                                      onClick={() => {
                                        setEditSizeIdx(idx);
                                        setEditSizeQtc(quantity);
                                        setEditSizeDescription(description);
                                      }}
                                    />
                                    <DeleteOutlined
                                      onClick={() => onDeleteSize(size?.name)}
                                    />
                                  </>
                                ) : (
                                  <div>
                                    <CheckOutlined
                                      className="mr-2"
                                      onClick={onEditSize}
                                    />
                                    <CloseOutlined
                                      onClick={() => {
                                        setEditSizeIdx("");
                                        setEditSizeQtc("");
                                        setEditSizeDescription("");
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </List.Item>
                        </div>
                      )}
                    </Draggable>
                  )}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Col>
  );
};

const checkAlreadyDeclered = (sizeName, store = []) => {
  let flag = store.findIndex((item) => item?.size?.name === sizeName);
  return flag >= 0;
};

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  // padding: grid,
  // width: 250,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});
