import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  FETCH_SINGLE_COLLABORATOR,
  FILE_UPLOAD,
  UPDATE_COUPON_STATUS,
  UPDATE_COUPON_VELIDATION,
} from "../graphql/modules";
import {
  Input,
  Popconfirm,
  Pagination,
  Select,
  DatePicker,
  Tag,
  Button,
  Modal,
  Spin,
} from "antd";
import { DynamicList } from "../components/Shared";
import { errorNotify, getFile, successNotify, warnNotify } from "../util";
import { Fragment } from "react";
import moment from "moment";
import { couponStatus } from "../config";
import { UserProfile } from "../components/AdminProfile";
import dayjs from "dayjs";

export const SingleCollaborator = ({ match }) => {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});
  const [couponDetails, setCouponDetails] = useState({});
  const [paidModalVisible, setPaidModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const { data, loading, error, refetch } = useQuery(
    FETCH_SINGLE_COLLABORATOR,
    {
      variables: {
        limit,
        offset: page - 1,
        userId: match.params.id,
        ...filterData,
      },
      fetchPolicy: "no-cache",
    }
  );

  const collaborator = data?.FetchSingleCollaboratorAdmin?.collaborator || {};
  const couponList = data?.FetchSingleCollaboratorAdmin?.coupons || [];
  const total = data?.FetchSingleCollaboratorAdmin?.count || 0;

  // update coupon status
  const [UpdateCouponMutation, { loading: statusLoading }] =
    useMutation(UPDATE_COUPON_STATUS);
  const updateCouponStatus = async (
    id,
    status,
    evidenceLink = "",
    desc = "",
    cancellationNote = ""
  ) => {
    try {
      const {
        data: { UpdateCoupneStatus },
      } = await UpdateCouponMutation({
        variables: {
          couponId: id,
          status,
          evidenceLink,
          desc,
          cancellationNote,
        },
      });
      if (UpdateCoupneStatus.success) {
        successNotify(UpdateCoupneStatus.message);
        refetch();
        setCouponDetails({});
        setPaidModalVisible(false);
        setCancelModalVisible(false);
      } else {
        warnNotify(UpdateCoupneStatus.message);
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };

  // update coupon validation time
  const [UpdateCouponAdminMutation] = useMutation(UPDATE_COUPON_VELIDATION);
  const updateCoupon = async (id, dateMilis, discountInPercent) => {
    try {
      const {
        data: { UpdateCouponAdmin },
      } = await UpdateCouponAdminMutation({
        variables: {
          couponId: id,
          dateMilis,
          discountInPercent: Number(discountInPercent),
        },
      });
      if (UpdateCouponAdmin.success) {
        successNotify(UpdateCouponAdmin.message);
        refetch();
      } else {
        warnNotify(UpdateCouponAdmin.message);
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };
  const onChangeDate = (value, id) => {
    if (value) {
      updateCoupon(id, value.format("x"));
    }
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  // control pay modal
  const openCancalation = (couponData) => {
    setCancelModalVisible(true);
    setCouponDetails(couponData);
  };
  // control pay modal
  const handlePaid = (couponData) => {
    setPaidModalVisible(true);
    setCouponDetails(couponData);
  };
  const handleModalCancel = () => {
    setCouponDetails({});
    setPaidModalVisible(false);
    setCancelModalVisible(false);
  };

  //image upload
  const [FileUploadMutaion, { loading: uploading }] = useMutation(FILE_UPLOAD);
  const uploadImageForEvidence = async (e) => {
    try {
      const {
        data: { SingleUpload },
      } = await FileUploadMutaion({
        variables: {
          file: e.target.files[0],
        },
      });
      if (SingleUpload.success) {
        setCouponDetails({
          ...couponDetails,
          evidenceLink: SingleUpload?.filename,
        });
      } else {
        warnNotify(SingleUpload.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const columns = [
    {
      title: "#",
      render: (_text, _row, index) => index + 1,
    },
    {
      title: "Coupon Code",
      dataIndex: "code",
    },
    {
      title: "Coupon Used",
      dataIndex: "usedCount",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, { validUntil }) => (
        <CouponStatus text={text} validUntil={validUntil} />
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (value) => moment(Number(value)).format("YYYY-MM-DD_HH:mm"),
    },
    {
      title: "Valid Until",
      dataIndex: "validUntil",
      render: (value, record) => {
        return (
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            style={{ minWidth: "180px" }}
            disabledDate={disabledDate}
            value={value !== "0" ? dayjs(Number(value)) : null}
            onChange={(value) => onChangeDate(value, record._id)}
            showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
          />
        );
      },
    },
    {
      title: "Dicount(%)",
      dataIndex: "discountInPercent",
      render: (_, record) => (
        <DiscountInput {...record} updateCoupon={updateCoupon} />
      ),
    },
    {
      title: "Action",
      width: "25%",
      dataIndex: "_id",
      render: (_, record) => (
        <Fragment>
          {record.status === couponStatus.Denied && (
            <>
              <Popconfirm
                title="Are you sure?"
                okText="Accept"
                okType="primary"
                onConfirm={() =>
                  updateCouponStatus(record._id, couponStatus.Accepted)
                }
              >
                <Button className="mr-1">Accept</Button>
                {/* <i className="fas fa-redo" style={{ cursor: "pointer" }}></i> */}
              </Popconfirm>
            </>
          )}
          {(record.status === couponStatus.Canceled ||
            record.status === couponStatus.Expired ||
            (record.validUntil < Date.now() &&
              record.status === couponStatus.Accepted)) && (
            <React.Fragment>
              <Button className="mr-1" onClick={() => handlePaid(record)}>
                Mark as paid
              </Button>
              <Popconfirm
                title="Are you sure?"
                okText="Reactive"
                okType="primary"
                onConfirm={() =>
                  updateCouponStatus(record._id, couponStatus.Accepted)
                }
              >
                <Button className="mr-1">Reactive</Button>
                {/* <i className="fas fa-redo" style={{ cursor: "pointer" }}></i> */}
              </Popconfirm>
            </React.Fragment>
          )}
          {record.status === couponStatus.Paused && (
            <>
              <Popconfirm
                title="Are you sure?"
                okText="Reactive"
                okType="primary"
                onConfirm={() =>
                  updateCouponStatus(record._id, couponStatus.Accepted)
                }
              >
                <Button className="mr-1">Reactive</Button>
                {/* <i className="fas fa-redo" style={{ cursor: "pointer" }}></i> */}
              </Popconfirm>
              <Button onClick={() => openCancalation(record)} className="mr-1">
                Cancel
              </Button>
            </>
          )}
          {record.status === couponStatus.Accepted &&
            record.validUntil > Date.now() && (
              <>
                <Popconfirm
                  title="Are you sure?"
                  okText="Pause"
                  okType="primary"
                  onConfirm={() =>
                    updateCouponStatus(record._id, couponStatus.Paused)
                  }
                >
                  <Button className="mr-1">Pause</Button>
                  {/* <i className="fas fa-redo" style={{ cursor: "pointer" }}></i> */}
                </Popconfirm>
                <Button
                  className="mr-1"
                  onClick={() => openCancalation(record)}
                >
                  Cancel
                </Button>
              </>
            )}
          {record.status === couponStatus.Pending && (
            <>
              <Popconfirm
                title="Are you sure?"
                okText="Accept"
                okType="primary"
                onConfirm={() =>
                  updateCouponStatus(record._id, couponStatus.Accepted)
                }
              >
                <Button className="mr-1">Accept</Button>
                {/* <i className="fas fa-check" style={{ cursor: "pointer" }}></i> */}
              </Popconfirm>
              <Popconfirm
                title="Are you sure?"
                okText="Deny"
                okType="primary"
                onConfirm={() =>
                  updateCouponStatus(record._id, couponStatus.Denied)
                }
              >
                <Button>Deny</Button>
                {/* <i className="fas fa-check" style={{ cursor: "pointer" }}></i> */}
              </Popconfirm>
            </>
          )}
        </Fragment>
      ),
    },
  ];
  // page handler
  const onChangePagination = (p) => {
    setPage(p);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };
  // status change function
  const handleStataus = (value) => {
    if (!value) {
      delete filterData.status;
      setFilterData({ ...filterData });
    } else {
      setFilterData({ ...filterData, status: value });
    }
  };
  const searchComponent = (
    <div className="d-flex justify-content-between align-items-center">
      <Input
        onChange={handleInput}
        name="code"
        value={filterData.code}
        size="large"
        className="mr-2"
        placeholder="Search by coupon code"
      />
      <Select
        style={{ width: "100%" }}
        className="mr-2"
        value={filterData.status || ""}
        size="large"
        onChange={handleStataus}
      >
        <Select.Option value="">All</Select.Option>
        <Select.Option value={couponStatus.Pending}>Pending</Select.Option>
        <Select.Option value={couponStatus.Accepted}>Accepted</Select.Option>
        <Select.Option value={couponStatus.Canceled}>Canceled</Select.Option>
        <Select.Option value={couponStatus.Expired}>Expired</Select.Option>
        <Select.Option value={couponStatus.Paid}>Paid</Select.Option>
      </Select>
      {/* <Button type="primary" className="mr-2" onClick={onSubmitSearch}>
        Search
      </Button>
      <Button type="default" onClick={onReset}>
        Reset
      </Button> */}
    </div>
  );

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  return (
    <Fragment>
      <UserProfile user={collaborator} showCollaboratorInfo loading={loading} />
      <DynamicList
        title="List of Coupons"
        rowKey={(row) => row._id}
        loading={loading}
        columns={columns}
        dataSource={couponList || []}
        tableFilter={searchComponent}
        pagination={
          <Pagination
            className="pagination"
            total={total}
            pageSize={limit}
            current={page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        }
        expandable={{
          expandedRowRender: (record) => <ExpandedComponet {...record} />,
        }}
      />
      <Modal
        title="Cancellation Note"
        open={cancelModalVisible}
        okText="Ok"
        onCancel={handleModalCancel}
        onOk={() =>
          updateCouponStatus(
            couponDetails?._id,
            couponStatus.Canceled,
            "",
            "",
            couponDetails?.cancellationNote
          )
        }
        okButtonProps={{
          disabled: !couponDetails?.cancellationNote,
          loading: statusLoading,
        }}
      >
        <div>
          <label>Cancellation note</label>
          <Input.TextArea
            value={couponDetails?.cancellationNote}
            onChange={(e) =>
              setCouponDetails({
                ...couponDetails,
                cancellationNote: e.target.value,
              })
            }
            placeholder="Notes..."
          />
        </div>
      </Modal>
      <Modal
        title="Payment details"
        open={paidModalVisible}
        okText="Ok"
        onCancel={handleModalCancel}
        onOk={() =>
          updateCouponStatus(
            couponDetails?._id,
            couponStatus?.Paid,
            couponDetails?.evidenceLink,
            couponDetails?.desc
          )
        }
        okButtonProps={{
          disabled: !couponDetails?.evidenceLink || !couponDetails?.desc,
          loading: statusLoading,
        }}
      >
        <Spin spinning={uploading}>
          <div className="mb-3">
            <div className="mb-2">
              <img
                width="300"
                alt="test"
                src={
                  couponDetails?.evidenceLink
                    ? getFile(couponDetails?.evidenceLink)
                    : "/assets/img/noimage.png"
                }
              />
            </div>
            <div>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .webp"
                onChange={uploadImageForEvidence}
                readOnly
                id="reset-input"
              />
            </div>
          </div>
        </Spin>
        <div>
          <label>Comments</label>
          <Input.TextArea
            value={couponDetails?.desc}
            onChange={(e) =>
              setCouponDetails({ ...couponDetails, desc: e.target.value })
            }
            placeholder="Comments..."
          />
        </div>
      </Modal>
    </Fragment>
  );
};

const CouponStatus = ({ text, validUntil }) => {
  if (validUntil < Date.now() && couponStatus.Accepted === text) {
    return <Tag color="volcano">Expired</Tag>;
  }
  switch (text) {
    case couponStatus.Denied:
      return <Tag color="red">{text}</Tag>;
    case couponStatus.Accepted:
      return <Tag color="geekblue">{text}</Tag>;
    case couponStatus.Pending:
      return <Tag color="orange">{text}</Tag>;
    case couponStatus.Paid:
      return <Tag color="green">{text}</Tag>;
    case couponStatus.Paused:
      return <Tag color="magenta">{text}</Tag>;
    default:
      return <Tag color="cyan">{text}</Tag>;
  }
};

const DiscountInput = ({ _id, discountInPercent, updateCoupon }) => {
  const [value, setValue] = useState(discountInPercent || 0);
  return (
    <Input
      type="number"
      value={value}
      onChange={(e) => {
        if (
          (Number(e.target.value) > 0 && Number(e.target.value) < 100) ||
          !e.target.value
        )
          setValue(e.target.value);
      }}
      onBlur={() => updateCoupon(_id, null, value)}
    />
  );
};

const ExpandedComponet = ({ createdAt, moneyEarned, desc, evidenceLink }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="row">
      <Modal
        title="Evidence image"
        open={visible}
        onOk={() => setVisible(false)}
        cancelButtonProps={{ className: "d-none" }}
        onCancel={() => setVisible(false)}
      >
        <img src={getFile(evidenceLink)} alt="" />
        <p className="mt-3 mb-0">
          <b className="mr-2">Description :</b>
          {desc || "N/A"}
        </p>
      </Modal>
      <div className="col-md-3">
        <p>
          <b className="mr-2">Created at:</b>
          {moment(Number(createdAt)).format("YYYY-MM-DD_HH:mm")}
        </p>
      </div>
      <div className="col-md-3">
        <p>
          <b className="mr-2">Money earned:</b>{(moneyEarned || 0).toFixed(2)} USD
        </p>
      </div>
      <div className="col-md-3">
        <p className="d-flex align-items-center">
          {evidenceLink && (
            <Button className="mr-2" onClick={() => setVisible(true)}>
              See Evidence
            </Button>
          )}
          {/* {evidenceLink ? (
            <>
              {evidenceLink?.split("-")[2]}
              <EyeOutlined
                className="ml-2"
                style={{ fontSize: "20px" }}
                onClick={() => setVisible(true)}
              />
            </>
          ) : (
            "N/A"
          )} */}
        </p>
      </div>
      {/* <div className="col-md-12">
        <p>
          <b className="mr-2">Description :</b>
          {desc || "N/A"}
        </p>
      </div> */}
    </div>
  );
};
