import gql from "graphql-tag";

export const FETCH_CATEGORY = gql`
  query FetchCategoryAdmin($limit: Int, $offset: Int) {
    FetchCategoryAdmin(limit: $limit, offset: $offset) {
      code
      success
      message
      result {
        _id
        name
        # isFetured
        isColored
        sizeType
        coverImage
        isShowAllSize
        isImagedCategory
        subcategory {
          _id
          name
          sizeType
          products {
            _id
          }
        }
        products {
          _id
          name
          gallery
        }
      }
    }
  }
`;

export const FETCH_FILTER_CATEGORYLIST_BY_PRODUCT = gql`
  query FetchCategoryListExceptProduct($productId: ID) {
    FetchCategoryListExceptProduct(productId: $productId) {
      categories {
        _id
        sizeType
        isImagedCategory
        name
        # isAccesories
      }
      categoryWithSubcategory {
        _id
        name
        sizeType
        # isAccesories
        subcategory {
          _id
          sizeType
          name
          # isAccesories
        }
      }
    }
  }
`;

export const FETCH_CATEGORY_WITH_PRODUCT = gql`
  query FetchCategoryAdmin($limit: Int, $offset: Int, $categoryName: String) {
    FetchCategoryAdmin(
      limit: $limit
      offset: $offset
      categoryName: $categoryName
    ) {
      count
      result {
        _id
        name
        sizeType
        isImagedCategory
        subcategory {
          _id
          name
          sizeType
          products {
            _id
            name
            gallery
            colors {
              gallery
              topGallery
              bottomGallery
            }
            isAccessories
            status
          }
        }
        products {
          _id
          name
          gallery
          colors {
            gallery
            topGallery
            bottomGallery
          }
          isAccessories
          status
        }
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation AddCategory(
    $name: String!
    $isColored: Boolean
    $sizeType: ProductSizeType
    $coverImage: String
    $isImagedCategory: Boolean
  ) {
    AddCategory(
      name: $name
      isColored: $isColored
      sizeType: $sizeType
      coverImage: $coverImage
      isImagedCategory: $isImagedCategory
    ) {
      code
      success
      message
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($categoryId: ID!) {
    DeleteCategory(categoryId: $categoryId) {
      code
      success
      message
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation EditCategory(
    $categoryId: ID!
    $name: String!
    $isColored: Boolean
    $coverImage: String
    $isShowAllSize: Boolean
    $isImagedCategory: Boolean
  ) {
    EditCategory(
      categoryId: $categoryId
      name: $name
      isColored: $isColored
      coverImage: $coverImage,
      isShowAllSize: $isShowAllSize,
      isImagedCategory: $isImagedCategory
    ) {
      code
      success
      message
    }
  }
`;

export const FETCH_SINGLE_CATEGORY = gql`
  query FetchSingleCategoryAdmin($categoryId: ID!) {
    FetchSingleCategoryAdmin(categoryId: $categoryId) {
      code
      success
      message
      result {
        _id
        name
        sizeType
        isShowAllSize
        subcategory {
          _id
          name
          sizeType
          coverImage
          isShowAllSize
          products {
            name
            price
          }
          subcategoryGroup {
            _id
            name
          }
        }
        subcategoryGroups {
          _id
          name
          subcategory {
            _id
            name
            coverImage
            isShowAllSize
            products {
              _id
            }
          }
        }
        products {
          _id
          name
          isNewProduct
          price
          topPrice
          bottomPrice
          isAccessories
          gallery
          status
          sizeType
          colors {
            topColor
            bottomColor
            topGallery
            bottomGallery
            gallery
          }
          category {
            _id
            name
          }
          subcategory {
            _id
            name
          }
        }
      }
    }
  }
`;

// subcategory section

export const FETCH_SINGLE_SUBCATEGORY = gql`
  query FetchSingleSubcategoryAdmin($subcategoryId: ID!) {
    FetchSingleSubcategoryAdmin(subcategoryId: $subcategoryId) {
      code
      success
      message
      result {
        _id
        name
        sizeType
        isShowAllSize
        products {
          _id
          name
          price
          topPrice
          bottomPrice
          gallery
          status
          isNewProduct
          isAccessories
          sizeType
          colors {
            topColor
            bottomColor
            topGallery
            bottomGallery
            gallery
          }
          category {
            _id
            name
          }
          subcategory {
            _id
            name
          }
        }
      }
    }
  }
`;

export const CREATE_SUBCATEGORY = gql`
  mutation AddSubcategory(
    $categoryId: ID!
    $name: String!
    $sizeType: ProductSizeType
    $coverImage: String
    $subcategoryGroup: ID!
  ) {
    AddSubcategory(
      categoryId: $categoryId
      name: $name
      sizeType: $sizeType
      coverImage: $coverImage
      subcategoryGroup: $subcategoryGroup
    ) {
      code
      success
      message
    }
  }
`;

export const UPDATE_SUBCATEGORY = gql`
  mutation EditSubcategory(
    $subcategoryId: ID!
    $name: String!
    $coverImage: String
    $subcategoryGroupId: ID!
    $isShowAllSize: Boolean
  ) {
    EditSubcategory(
      subcategoryId: $subcategoryId
      name: $name
      coverImage: $coverImage
      subcategoryGroupId: $subcategoryGroupId
      isShowAllSize: $isShowAllSize
    ) {
      code
      success
      message
    }
  }
`;

export const DELETE_SUBCATEGORY = gql`
  mutation DeleteSubcategory($subcategoryId: ID!) {
    DeleteSubcategory(subcategoryId: $subcategoryId) {
      code
      success
      message
    }
  }
`;

// drag and drop apis
export const CATEGORY_LIST_UPDATE = gql`
  mutation UpdateCategoryPosition($data: [ID]) {
    UpdateCategoryPosition(data: $data) {
      code
      success
      message
    }
  }
`;
export const SUBCATEGORY_LIST_UPDATE = gql`
  mutation UpdateSubcategoryPosition($data: [ID]) {
    UpdateSubcategoryPosition(data: $data) {
      code
      success
      message
    }
  }
`;

export const CREATE_SUBCATEGORY_GROUP = gql`
  mutation AddSubcategoryGroup($categoryId: ID!, $name: String!) {
    AddSubcategoryGroup(categoryId: $categoryId, name: $name) {
      message
      success
    }
  }
`;

export const DELETE_SUBCATEGORY_GROUP = gql`
  mutation DeleteSubcategoryGroup($subcategoryGroupId: ID!) {
    DeleteSubcategoryGroup(subcategoryGroupId: $subcategoryGroupId) {
      message
      success
    }
  }
`;

export const EDIT_SUBCATEGORY_GROUP = gql`
  mutation EditSubcategoryGroup($id: ID!, $name: String!) {
    EditSubcategoryGroup(id: $id, name: $name) {
      message
      success
    }
  }
`;
