import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import React from "react";
import { useState } from "react";
import {
  CREATE_ORDER_DISCOUNT,
  DELETE_ORDER_DISCOUNT,
  FETCH_DISCOUNT_ON_PURCHASE,
  UPDATE_ORDER_DISCOUNT,
} from "../../graphql/modules/settings";
import { successNotify, warnNotify } from "../../util";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const DiscountOnPrice = () => {
  const [inputData, setInputData] = useState({ status: "Active" });
  const [visible, setVisible] = useState(false);

  const { data, refetch } = useQuery(FETCH_DISCOUNT_ON_PURCHASE);
  const allDiscount = data?.FetchOrderDiscount?.result || [];

  // create discount
  const [CreateDiscountMutation, { loading: createLoading }] = useMutation(
    CREATE_ORDER_DISCOUNT
  );
  const onCreateDiscount = async () => {
    try {
      const {
        data: { CreateOrderDiscount },
      } = await CreateDiscountMutation({
        variables: {
          inputData: {
            ...inputData,
            amount: parseFloat(inputData?.amount),
            discountAmount: parseFloat(inputData?.discountAmount),
          },
        },
      });
      if (CreateOrderDiscount?.success) {
        successNotify(CreateOrderDiscount.message);
        refetch();
        handleCancelModal();
      } else {
        warnNotify(CreateOrderDiscount.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // edit discount
  const [UpdateDiscountMutation, { loading }] = useMutation(
    UPDATE_ORDER_DISCOUNT
  );
  const discountUpdate = async () => {
    const id = inputData?._id;
    delete inputData?._id;
    delete inputData?.createdAt;
    try {
      const {
        data: { UpdatedOrderDiscount },
      } = await UpdateDiscountMutation({
        variables: {
          id,
          inputData: {
            ...inputData,
            amount: parseFloat(inputData?.amount),
            discountAmount: parseFloat(inputData?.discountAmount),
          },
        },
      });
      if (UpdatedOrderDiscount.success) {
        successNotify(UpdatedOrderDiscount.message);
        handleCancelModal();
        refetch();
      } else {
        warnNotify(UpdatedOrderDiscount.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete discount
  const [DeleteDiscountMutation, { loading: deleteLoading }] = useMutation(
    DELETE_ORDER_DISCOUNT
  );
  const deleteDiscount = async (id) => {
    try {
      const {
        data: { DeleteOrderDiscount },
      } = await DeleteDiscountMutation({
        variables: {
          id,
        },
      });
      if (DeleteOrderDiscount.success) {
        successNotify(DeleteOrderDiscount.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // input handler
  const onChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  // change date
  const onChangeDate = (value) => {
    setInputData({ ...inputData, validUntil: value ? value.format("x") : "" });
  };

  // control modal
  const handleCancelModal = () => {
    setInputData({});
    setVisible(false);
  };

  const openModal = (data) => {
    setInputData({ ...data });
    setVisible(true);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  const column = [
    {
      title: "#",
      render: (_text, _row, index) => index + 1,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (value) => moment(Number(value)).format("YYYY-MM-DD_HH:mm"),
    },
    { title: "Price", dataIndex: "amount" },
    { title: "Discount", dataIndex: "discountAmount" },
    {
      title: "Staus",
      dataIndex: "status",
      render: (text, { validUntil }) => (
        <DiscountStatus text={text} validUntil={validUntil} />
      ),
    },
    {
      title: "Duration",
      dataIndex: "validUntil",
      render: (value) => moment(Number(value)).format("YYYY-MM-DD_HH:mm"),
    },
    {
      title: "Action",
      render: (record) => (
        <div>
          <Popconfirm
            title="Are you sure?"
            okType="danger"
            onConfirm={() => deleteDiscount(record?._id)}
          >
            <DeleteOutlined style={{ color: "red" }} className="mr-2" />
          </Popconfirm>
          <EditOutlined onClick={() => openModal(record)} />
        </div>
      ),
    },
  ];

  const disable =
    !inputData?.amount || !inputData?.discountAmount || !inputData?.validUntil;

  return (
    <div>
      <Button className="mb-3" type="primary" onClick={() => setVisible(true)}>
        Create
      </Button>
      <Table
        loading={loading}
        columns={column}
        dataSource={allDiscount}
        pagination={false}
        rowKey={(row) => row?._id}
      />
      <Modal
        title="Discount"
        open={visible}
        okButtonProps={{
          loading: createLoading,
          disabled: createLoading || disable,
        }}
        onOk={inputData?._id ? discountUpdate : onCreateDiscount}
        onCancel={handleCancelModal}
      >
        <div className="mb-3">
          <label>Price (over or equal)</label>
          <Input
            placeholder="Price"
            size="large"
            type="number"
            name="amount"
            value={inputData?.amount || ""}
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label>Discount amount</label>
          <Input
            placeholder="Discount"
            size="large"
            type="number"
            name="discountAmount"
            value={inputData?.discountAmount}
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label>Valid untill</label>
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            style={{ width: "100%" }}
            // disabledDate={disabledDate}
            value={
              inputData?.validUntil ? dayjs(Number(inputData?.validUntil)) : ""
            }
            onChange={(value) => onChangeDate(value)}
            // showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
            showTime
          />
        </div>
        <div className="mb-3">
          <label>Status</label>
          <Select
            value={inputData?.status}
            style={{ width: "100%" }}
            size="large"
            onChange={(value) => setInputData({ ...inputData, status: value })}
          >
            <Select.Option value="Active">Active</Select.Option>
            <Select.Option value="Deactivate">Deactivate</Select.Option>
          </Select>
        </div>
      </Modal>
    </div>
  );
};

export default DiscountOnPrice;

const DiscountStatus = ({ text, validUntil }) => {
  if (validUntil < Date.now() && "Active" === text) {
    return <Tag color="volcano">Expired</Tag>;
  }
  switch (text) {
    case "Deactivate":
      return <Tag color="red">{text}</Tag>;
    case "Active":
      return <Tag color="green">{text}</Tag>;
    case "Expired":
      return <Tag color="volcano">Expired</Tag>;
    default:
      return <Tag color="cyan">{text}</Tag>;
  }
};
